import React from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  flex: 1;
  text-align: center;
  margin: 60px 20px;
`

const NotFoundPage = () => (
  <Container>
    <h1>
      NOT FOUND{' '}
      <span role="img" aria-label="cat emoji">
        😿
      </span>
    </h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Container>
)

export default NotFoundPage
